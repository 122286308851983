import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Container from "react-bootstrap/Container";
import { graphql } from "gatsby";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  //   const image = getImage(post.frontmatter.featuredImage)
  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
      />
      <Container className="text-white w-75">
        <br />
        <br />
        <br />
        <br />
        <div className="text-center">
        <h1 className="text-center display-6 text-info">{post.frontmatter.title}</h1>
        <small className="text-muted">Written by {post.frontmatter.author}</small></div>
        <div className="me-auto fs-5 text-break fw-lighter" dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(fromNow: true)
        author
      }
      excerpt(pruneLength: 160)
    }
  }
`;
